import axios from 'axios'
import { API_ORGANIZATION } from 'utils/constants'
import { Dispatch } from 'types/common'
import { OrganizationData } from 'types/Organization'
import { useQuery } from '@tanstack/react-query'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const LOAD_COUNTRIES = 'UPDATE_COUNTRIES'

export function loadOrganization() {
  return function(dispatch: Dispatch) {
    return axios.get(API_ORGANIZATION)
    .then(response => {
      return dispatch({
        type: UPDATE_ORGANIZATION,
        payload: response.data
      })
    })
  }
}

function getOrganization() {
  return axios.get<OrganizationData>(API_ORGANIZATION).then(response => response.data)
}

export function useOrganization() {
  return useQuery(['organization', API_ORGANIZATION], getOrganization)
}

export function updateOrganization(formData: OrganizationData) {
  return function(dispatch: Dispatch) {
    return axios.patch(API_ORGANIZATION, formData)
    .then(response => {
      return dispatch({
        type: UPDATE_ORGANIZATION,
        payload: response.data
      })
    })
  }
}

export function loadCountries() {
  return function(dispatch: Dispatch) {
    return axios({
      method: 'options',
      url: API_ORGANIZATION
    })
    .then(response => {
      const countries = response?.data?.actions?.PUT?.country?.choices || []

      return dispatch({
        type: LOAD_COUNTRIES,
        payload: countries
      })
    })
  }
}
