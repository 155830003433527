import React, { useState } from "react";
import { Button, Form, Input, Select, Switch, Typography } from "antd";
import { FieldData, Store } from "rc-field-form/lib/interface";
import InfoTooltip from "../InfoTooltip";
import styles from "./RequestSignatureForm.module.scss";
import {
  RequestSignatureAlgorithms,
  RequestSignatureKeyFormat,
  RequestSignatureHashMethod,
  RequestSignaturePayloadEncoding,
  RequestSignatureSignatureEncoding,
  RequestSignatureTimestampPrecision,
  IRequestSignature,
} from "types/RequestSignature";
import { hasErrors } from "utils/utils";

interface Props {
  settings: IRequestSignature | null;
  isSaving: boolean;
  onSubmit: (values: IRequestSignature) => Promise<void>;
}

const initialValue: Omit<IRequestSignature, "id"> = {
  algorithm: RequestSignatureAlgorithms.RSA2,
  key_format: RequestSignatureKeyFormat.PEM,
  client_id: "",
  secret_key: "",
  appboxo_public_key: "",
  partner_public_key: "",
  identity: null,
  headers_map: {
    client_id: "X-Client-Id",
    timestamp: "X-Timestamp",
    signature: "X-Signature",
    identity: "X-Identity",
    nonce: "X-Nonce",
    merchant_id: "X-Merchant-Id",
  },
  hash_method: RequestSignatureHashMethod.SHA256,
  signature_template: "{signature}",
  payload_template: "{request_method}.{url}.{client_id}.{timestamp}.{payload}",
  payload_format: RequestSignaturePayloadEncoding.NONE,
  body_format: RequestSignaturePayloadEncoding.NONE,
  signature_format: RequestSignatureSignatureEncoding.HEX,
  timespec: RequestSignatureTimestampPrecision.SECONDS,
  use_nonce: false,
  nonce_length: 32,
  is_body_separator_with_space: false,
  sort_body_keys: false,
};

const RequestSignatureForm = ({ settings, isSaving, onSubmit }: Props) => {
  const [form] = Form.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSettingsEnabled, setIsSettingsEnabled] = useState(Boolean(settings));
  const initialValues = settings ?? initialValue;

  const handleSubmit = (values: Store) => {
    setSubmitDisabled(true);
    onSubmit({
      ...settings,
      ...values,
      headers_map: JSON.parse(values.headers_map),
    } as IRequestSignature);
  };

  const hasChangesOrInvalid = (
    changedValues: FieldData[],
    allFields: FieldData[]
  ) => {
    setSubmitDisabled(hasErrors(allFields));
  };

  return (
    <div className={styles["request-signature-form-wrapper"]}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit}
        onFieldsChange={hasChangesOrInvalid}
        initialValues={{
          ...initialValues,
          headers_map: JSON.stringify(initialValues.headers_map),
        }}
      >
        <div className={styles["request-signature-form"]}>
          <div className={styles["request-signature-form__section"]}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography.Title level={3}>
                Request Signature Settings
              </Typography.Title>
              <Form.Item style={{ marginBottom: 11, marginLeft: 135 }}>
                <Switch
                  checked={isSettingsEnabled}
                  onChange={(checked) => {
                    setIsSettingsEnabled(checked);
                  }}
                />
              </Form.Item>
            </div>
            {isSettingsEnabled && (
              <>
                <Form.Item
                  name="algorithm"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Algorithm
                      <InfoTooltip title="Algorithm used for signature" />
                    </span>
                  }
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select Algorithm",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={RequestSignatureAlgorithms.RSA2}>
                      RSA2
                    </Select.Option>
                    <Select.Option value={RequestSignatureAlgorithms.HMAC}>
                      HMAC
                    </Select.Option>
                    <Select.Option value={RequestSignatureAlgorithms.ECDSA}>
                      ECDSA
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="key_format"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Key format for RSA or ECDSA
                      <InfoTooltip title="Key format for RSA or ECDSA" />
                    </span>
                  }
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select Key format for RSA or ECDSA",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={RequestSignatureKeyFormat.PEM}>
                      PEM
                    </Select.Option>
                    <Select.Option value={RequestSignatureKeyFormat.DER}>
                      DER
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="client_id"
                  colon={false}
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Api client ID
                      <InfoTooltip title="Partner api client ID" />
                    </span>
                  }
                >
                  <Input placeholder="Please enter API client ID" />
                </Form.Item>
                <div className={styles["long-textarea"]}>
                  <Form.Item
                    name="secret_key"
                    colon={false}
                    label={
                      <span className={styles["pass-colors-label"]}>
                        HMAC secret
                        <InfoTooltip title="Any key or password, used in HMAC only" />
                      </span>
                    }
                  >
                    <Input.TextArea
                      placeholder="Please enter HMAC secret"
                      rows={5}
                    />
                  </Form.Item>
                </div>
                <div className={styles["long-textarea"]}>
                  <Form.Item
                    name="appboxo_public_key"
                    colon={false}
                    label={
                      <span className={styles["pass-colors-label"]}>
                        Appboxo public key
                        <InfoTooltip title='Public key used for verifying request from Appboxo Miniapp Platform, used in RSA2 or ECDSA only, provide key without "-----BEGIN PRIVATE KEY-----" or "-----END PRIVATE KEY-----" in one line and in PCKS#1 format for RSA2' />
                      </span>
                    }
                  >
                    <Input.TextArea rows={5} readOnly />
                  </Form.Item>
                </div>
                <div className={styles["long-textarea"]}>
                  <Form.Item
                    name="partner_public_key"
                    colon={false}
                    label={
                      <span className={styles["pass-colors-label"]}>
                        Partner public key
                        <InfoTooltip title='Public key used for verifying request from Partner, used in RSA2 or ECDSA only, provide key without "-----BEGIN PRIVATE KEY-----" or "-----END PRIVATE KEY-----" in one line and in PCKS#1 format for RSA2' />
                      </span>
                    }
                  >
                    <Input.TextArea
                      placeholder="Please enter Partner public key"
                      rows={5}
                    />
                  </Form.Item>
                </div>
                <div className={styles["long-textarea"]}>
                  <Form.Item
                    name="headers_map"
                    label={
                      <span className={styles["pass-colors-label"]}>
                        Headers map
                        <InfoTooltip title="Mapping used for parsing or setting header keys in request. 'merchant_id' header value will be obtained from Integration. Default headers map: {'client_id': 'X-Client-Id', 'timestamp': 'X-Timestamp', 'signature': 'X-Signature', 'identity': 'X-Identity', 'nonce': 'X-Nonce', 'merchant_id': 'X-Merchant-Id'}" />
                      </span>
                    }
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Headers map",
                      },
                      {
                        validator: (_, value) => {
                          try {
                            JSON.parse(value);
                            return Promise.resolve();
                          } catch (error) {
                            return Promise.reject("Please enter valid JSON");
                          }
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Please enter Headers map"
                      rows={5}
                    />
                  </Form.Item>
                </div>
                <div className={styles["long-textarea"]}>
                  <Form.Item
                    name="signature_template"
                    label={
                      <span className={styles["pass-colors-label"]}>
                        Signature template
                        <InfoTooltip title="Signature header template. Default signature template: {signature}" />
                      </span>
                    }
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Signature template",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Please enter Signature template"
                      rows={5}
                    />
                  </Form.Item>
                </div>
                <div className={styles["long-textarea"]}>
                  <Form.Item
                    name="payload_template"
                    label={
                      <span className={styles["pass-colors-label"]}>
                        Signature payload template
                        <InfoTooltip title="Default payload template: {request_method}.{url}.{client_id}.{timestamp}.{payload}" />
                      </span>
                    }
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Signature payload template",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Please enter Signature payload template"
                      rows={5}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="identity"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Identity
                      <InfoTooltip title="Identity used in signature, can be any string, *Note:provide \{identity\} in 'Signature payload template' and header mapping 'identity' in 'Headers map'" />
                    </span>
                  }
                  colon={false}
                >
                  <Input placeholder="Please enter Identity" />
                </Form.Item>
                <Form.Item
                  name="hash_method"
                  label="Hash function used in signature"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select Hash function used in signature",
                    },
                  ]}
                >
                  <Select placeholder="Select hash method">
                    <Select.Option value={RequestSignatureHashMethod.MD5}>
                      MD5
                    </Select.Option>
                    <Select.Option value={RequestSignatureHashMethod.SHA1}>
                      SHA1
                    </Select.Option>
                    <Select.Option value={RequestSignatureHashMethod.SHA256}>
                      SHA256
                    </Select.Option>
                    <Select.Option value={RequestSignatureHashMethod.SHA384}>
                      SHA384
                    </Select.Option>
                    <Select.Option value={RequestSignatureHashMethod.SHA512}>
                      SHA512
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="payload_format"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Signature payload encoding
                      <InfoTooltip title="Encoding used before signing payload" />
                    </span>
                  }
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select Signature payload encoding",
                    },
                  ]}
                >
                  <Select placeholder="Please select Signature payload encoding">
                    <Select.Option value={RequestSignaturePayloadEncoding.NONE}>
                      none
                    </Select.Option>
                    <Select.Option
                      value={RequestSignaturePayloadEncoding.BASE64}
                    >
                      base64
                    </Select.Option>
                    <Select.Option
                      value={RequestSignaturePayloadEncoding.BASE64_URLSAFE}
                    >
                      base64_urlsafe
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="body_format"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Request data encoding
                      <InfoTooltip title="Encoding used on request data before constructing payload" />
                    </span>
                  }
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select Request data encoding",
                    },
                  ]}
                >
                  <Select placeholder="Please select Request data encoding">
                    <Select.Option value={RequestSignaturePayloadEncoding.NONE}>
                      none
                    </Select.Option>
                    <Select.Option
                      value={RequestSignaturePayloadEncoding.BASE64}
                    >
                      base64
                    </Select.Option>
                    <Select.Option
                      value={RequestSignaturePayloadEncoding.BASE64_URLSAFE}
                    >
                      base64_urlsafe
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="signature_format"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Signature encoding
                      <InfoTooltip title="Encoding used for signature representation" />
                    </span>
                  }
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select Signature encoding",
                    },
                  ]}
                >
                  <Select placeholder="Please select Signature encoding">
                    <Select.Option
                      value={RequestSignatureSignatureEncoding.HEX}
                    >
                      hex
                    </Select.Option>
                    <Select.Option
                      value={RequestSignatureSignatureEncoding.BASE64}
                    >
                      base64
                    </Select.Option>
                    <Select.Option
                      value={RequestSignatureSignatureEncoding.BASE64_URLSAFE}
                    >
                      base64_urlsafe
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="timespec"
                  label="Timestamp precision"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select Timestamp precision",
                    },
                  ]}
                >
                  <Select placeholder="Please select Timestamp precision">
                    <Select.Option
                      value={RequestSignatureTimestampPrecision.AUTO}
                    >
                      Auto
                    </Select.Option>
                    <Select.Option
                      value={RequestSignatureTimestampPrecision.HOURS}
                    >
                      Hours
                    </Select.Option>
                    <Select.Option
                      value={RequestSignatureTimestampPrecision.MINUTES}
                    >
                      Minutes
                    </Select.Option>
                    <Select.Option
                      value={RequestSignatureTimestampPrecision.SECONDS}
                    >
                      Seconds
                    </Select.Option>
                    <Select.Option
                      value={RequestSignatureTimestampPrecision.MILLISECONDS}
                    >
                      Milliseconds
                    </Select.Option>
                    <Select.Option
                      value={RequestSignatureTimestampPrecision.MICROSECONDS}
                    >
                      Microseconds
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="nonce_length"
                  label="Nonce length"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Nonce length",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter nonce length"
                    type="number"
                  />
                </Form.Item>
                <Form.Item
                  name="use_nonce"
                  valuePropName="checked"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Use nonce
                      <InfoTooltip title="Random string of 'Nonce length' used in signature, provide \{nonce\} in 'Signature payload template' and header mapping 'nonce' in 'Headers map'" />
                    </span>
                  }
                  colon={false}
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name="is_body_separator_with_space"
                  valuePropName="checked"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Use request data with spaces
                      <InfoTooltip title='Example: If false \{"a": "b"} will become \{"a":"b"\} in payload, if true \{"a":"b"\} will become \{"a": "b"\} in payload' />
                    </span>
                  }
                  colon={false}
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name="sort_body_keys"
                  valuePropName="checked"
                  label={
                    <span className={styles["pass-colors-label"]}>
                      Sort request data keys
                      <InfoTooltip title='Example: If true \{"b": "1", "a": "2"\} will become \{"a": "2", "b": "1"\} in payload' />
                    </span>
                  }
                  colon={false}
                >
                  <Switch />
                </Form.Item>
              </>
            )}
          </div>
          <div className={styles["request-signature-form__save-btn-wrapper"]}>
            <Button
              type="primary"
              htmlType="submit"
              size="small"
              loading={isSaving}
              disabled={submitDisabled}
            >
              {isSaving ? "Saving" : "Save"}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RequestSignatureForm;
