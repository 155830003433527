/*LIBS*/
import axios, { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
/*CONSTANTS*/
import {
  API_USER_APPS,
  API_ACTIVE_MINIAPPS,
  API_INTEGRATED_HOST_APPS,
  SCREENSHOTS,
  REQUEST_COMMERCIAL_MODEL,
  SCREENSHOTS_DELETE,
  API_APPS_HELP,
} from 'utils/constants'
/*TYPES*/
import { IFormData } from 'types'
import { IRequestCommercialModel } from 'types/Billing'

export const LOAD_MINI_APPS = 'LOAD_MINI_APPS'
export const LOAD_MINI_APPS_REQUEST = 'LOAD_MINI_APPS_REQUEST'
export const LOAD_MINI_APPS_ERROR = 'LOAD_MINI_APPS_ERROR'
export const UPDATE_MINI_APP = 'UPDATE_MINI_APP'
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES'

/*
 * action creators
 */
export function loadMiniApps() {
  return function (dispatch: Dispatch) {
    dispatch({ type: LOAD_MINI_APPS_REQUEST })
    return axios.get(API_USER_APPS)
      .then(response => {
        return dispatch({
          type: LOAD_MINI_APPS,
          miniApps: response.data
        })
      })
      .catch(e => {
        return dispatch({
          type: LOAD_MINI_APPS_ERROR,
          error: e
        })
      })
  }
}

export function addNewMiniapp(formData: FormData) {
  return axios.post(API_USER_APPS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function getMiniapp(appId: string) {
  return function () {
    return axios.get(API_USER_APPS + appId + '/')
  }
}

export function updateMiniApp(appId: string, formData: IFormData) {
  return function (dispatch: Dispatch) {
    const url = API_USER_APPS + appId + '/'
    return axios.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then(response => {
      return dispatch({
        type: UPDATE_MINI_APP,
        miniApp: response.data
      })
    })
  }
}

export function getMiniappDocuments(appId: string) {
  return function () {
    const url = API_USER_APPS + appId + '/documents/'
    return axios.get(url)
  }
}

export function deleteMiniappDocument(appId: string, documentId: number) {
  return function () {
    const url = API_USER_APPS + appId + '/documents/' + documentId + '/'
    return axios.delete(url)
  }
}

export function getIntegratedMiniApps() {
  return function () {
    return axios.get(API_ACTIVE_MINIAPPS + '?integrations__status=accepted')
  }
}

export function getIntegratedMiniApp(appId: number) {
  return function () {
    return axios.get(API_ACTIVE_MINIAPPS + appId)
  }
}

export function getIntegratedHostApps(miniAppId: number) {
  return function () {
    return axios.get(
      API_INTEGRATED_HOST_APPS + `?integrations__status=accepted&integrations__miniapp__id=${miniAppId}`
    )
  }
}

export function uploadMiniAppScreenshot(miniAppId: string, screenshot: File) {
  const formData = new FormData()
  formData.append('image', screenshot)
  formData.append('miniapp', miniAppId)
  const headers = { 'Content-Type': 'multipart/form-data' }
  return function () {
    return axios.post(SCREENSHOTS, formData, { headers })
  }
}

export function deleteMiniAppScreenshot(screenshotId: number) {
  return function () {
    return axios.delete(SCREENSHOTS + screenshotId + '/')
  }
}

export function deleteMiniAppScreenshots(screenshotIds: number[]) {
  return function () {
    return axios.delete(SCREENSHOTS_DELETE, { data: screenshotIds })
  }
}

export function requestCommercialModel(miniapp: number) {
  return function (): Promise<AxiosResponse<IRequestCommercialModel>> {
    return axios.post(REQUEST_COMMERCIAL_MODEL, { miniapp })
  }
}

export function loadCountries() {
  return function (dispatch: Dispatch) {
    return axios({
      method: 'options',
      url: API_APPS_HELP
    })
      .then(response => {
        const countries = response?.data?.actions?.POST?.countries?.child?.choices || []
        return dispatch({
          type: LOAD_COUNTRIES,
          payload: countries
        })
      })
  }
}
