import React from "react";
import RequestSignatureForm from "../../../../common/RequestSignatureForm/RequestSignatureForm";
import { IRequestSignature } from "types/RequestSignature";
import { message } from "antd";
import { useUpdateHostAppRequestSignature } from "queries/requestSignature";

interface Props {
  appId: string;
  settings: IRequestSignature | null;
  loadSuperApp: (id: string) => Promise<void>;
}

const SuperAppRequestSignature = ({ appId, settings, loadSuperApp }: Props) => {
  const { mutateAsync, isLoading } = useUpdateHostAppRequestSignature(appId);

  const onSubmit = async (data: IRequestSignature) => {
    try {
      await mutateAsync(data);
      await loadSuperApp(appId);
      message.success("Successfully saved");
    } catch (error) {
      message.error(
        "Something went wrong while updating request signature settings for host app"
      );
    }
  };

  return (
    <RequestSignatureForm
      settings={settings}
      isSaving={isLoading}
      onSubmit={onSubmit}
    />
  );
};

export default SuperAppRequestSignature;
