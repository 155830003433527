import axios from 'axios'
import {
  API_USER_HOST_APPS,
  API_ACTIVE_MINIAPPS
} from 'utils/constants'

export const LOAD_SUPER_APPS = 'LOAD_SUPER_APPS'
export const LOAD_SUPER_APPS_REQUEST = 'LOAD_SUPER_APPS_REQUEST'
export const LOAD_SUPER_APPS_ERROR = 'LOAD_SUPER_APPS_ERROR'
export const UPDATE_SUPER_APP = 'UPDATE_SUPER_APP'

export function loadSuperApps () {
  return function(dispatch, getState) {
    dispatch({type: LOAD_SUPER_APPS_REQUEST})
    return axios.get(API_USER_HOST_APPS)
      .then(response => {
        return dispatch({
          type: LOAD_SUPER_APPS,
          superApps: response.data
        })
      }).catch(e => {
        return dispatch({
          type: LOAD_SUPER_APPS_ERROR,
        })
    })
  }
}

export function getSuperApp (appId) {
  return function(dispatch, getState) {
    return axios.get(API_USER_HOST_APPS + appId + '/')
  }
}

export function getSuperAppActiveMiniApps (appId) {
  return function(dispatch, getState) {
    return axios.get(API_ACTIVE_MINIAPPS +`?integrations__status=accepted&integrations__hostapp__id=${appId}`)
  }
}

export function addSuperApp (formData) {
  return axios.post(API_USER_HOST_APPS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function updateSuperApp (appId, formData) {
  return function(dispatch, getState) {
    const url = API_USER_HOST_APPS + appId + '/'
    return axios.patch(url, formData).then(response => {
      return dispatch({
        type: UPDATE_SUPER_APP,
        superApp: response.data
      })
    })
  }
}
