export enum RequestSignatureAlgorithms {
  RSA2 = "RSA2",
  HMAC = "HMAC",
  ECDSA = "ECDSA",
}

export enum RequestSignatureKeyFormat {
  PEM = "PEM",
  DER = "DER",
}

export enum RequestSignatureHashMethod {
  MD5 = "MD5",
  SHA1 = "SHA-1",
  SHA224 = "SHA-224",
  SHA256 = "SHA-256",
  SHA384 = "SHA-384",
  SHA512 = "SHA-512",
}

export enum RequestSignaturePayloadEncoding {
  NONE = "none",
  BASE64 = "base64",
  BASE64_URLSAFE = "base64_urlsafe",
}

export enum RequestSignatureSignatureEncoding {
  HEX = "hex",
  BASE64 = "base64",
  BASE64_URLSAFE = "base64_urlsafe",
}

export enum RequestSignatureTimestampPrecision {
  AUTO = "auto",
  HOURS = "hours",
  MINUTES = "minutes",
  SECONDS = "seconds",
  MILLISECONDS = "milliseconds",
  MICROSECONDS = "microseconds",
}

export interface IRequestSignature {
  id: number;
  algorithm: RequestSignatureAlgorithms;
  key_format: RequestSignatureKeyFormat;
  client_id: string;
  secret_key: string;
  appboxo_public_key: string;
  partner_public_key: string;
  identity: string | null;
  headers_map: Record<string, string>;
  hash_method: RequestSignatureHashMethod;
  signature_template: string;
  payload_template: string;
  payload_format: RequestSignaturePayloadEncoding;
  body_format: RequestSignaturePayloadEncoding;
  signature_format: RequestSignatureSignatureEncoding;
  timespec: RequestSignatureTimestampPrecision;
  use_nonce: boolean;
  nonce_length: number;
  is_body_separator_with_space: boolean;
  sort_body_keys: boolean;
}
