import { ILogItem } from '../../types';
import React, { FC, useState } from 'react';
import { Button, Table } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

interface Props {
  data: ILogItem[],
  loading: boolean,
}

const columns: ColumnsType<ILogItem> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (value) => value || '-',
  },
  {
    title: 'REQUEST TYPE',
    dataIndex: 'request_type',
    key: 'request_type',
    render: (value) => value || '-',
  },
  {
    title: 'RESPONSE STATUS',
    dataIndex: 'response_status',
    key: 'response_status',
    render: (value) => value || '-',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    render: (value) => value || '-',
  },
  {
    title: 'CREATED AT',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : '-',
  },
];

const formatLogText = (log: string): string => {
  return log.replace(/\\"/g, '"')
}

const LogsTable: FC<Props> = ({data, loading}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const expandedRowRender = (record: ILogItem) => {
    return (
      <div>
        <p><strong>Request Headers:</strong> {record.request_headers ? formatLogText(record.request_headers) : '-'}</p>
        <p><strong>Request Payload:</strong> {record.request_payload ? formatLogText(record.request_payload) : '-'}</p>
        <p><strong>Response Content:</strong> {record.response_content ? formatLogText(record.response_content) : '-'}</p>
        <p><strong>Response Headers:</strong> {record.response_headers ? formatLogText(record.response_headers) : '-'}</p>
        <p><strong>CURL:</strong> {record.curl ? formatLogText(record.curl) : '-'}</p>
      </div>
    );
  };

  const expandIcon = ({ expanded, onExpand, record }: any) => (
    <Button
      icon={expanded ? <DownOutlined /> : <RightOutlined />}
      onClick={e => {
        e.stopPropagation();
        onExpand(record, e);
      }}
    />
  );

  const handleExpand = (expanded: boolean, record: ILogItem) => {
    setExpandedRowKeys(prevExpandedRowKeys => {
      if (expanded) {
        return [...prevExpandedRowKeys, record.id];
      } else {
        return prevExpandedRowKeys.filter(key => key !== record.id);
      }
    });
  };

  return <Table
    loading={loading}
    columns={columns}
    dataSource={data}
    rowKey="id"
    expandedRowRender={expandedRowRender}
    expandIcon={expandIcon}
    expandedRowKeys={expandedRowKeys}
    onExpand={handleExpand}
    pagination={{ pageSize: 20 }}
  />
}

export default LogsTable;
