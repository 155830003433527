/*CORE*/
import React, { useState, useEffect } from 'react'
/*LIBS*/
import {
  useHistory,
  useParams,
  useRouteMatch,
  Switch as RouterSwitch,
  Route,
  Redirect,
} from 'react-router-dom'
import { RangeValue } from 'rc-picker/lib/interface'
import { bindActionCreators, Dispatch } from 'redux'
import { Typography, message, Tabs } from 'antd'
import { connect } from 'react-redux'
import { get, isNull } from 'lodash'
import { AxiosResponse } from 'axios'
import moment from 'moment'
/*ACTIONS*/
import {
  deleteMiniAppScreenshots as deleteMiniAppScreenshotsAction,
  updateMiniApp as updateMiniAppAction,
  getMiniapp as getMiniappAction,
} from 'store/miniApps/actions'
import { getAppMetricsByRangeDate as getAppMetricsByRangeDateAction } from 'store/analytics/actions'
import { loadShowroomMiniApps as loadShowroomMiniAppsAction } from 'store/showroom/actions'
/*COMPONENTS*/
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import MiniAppSettings from '../components/MiniAppSettings/MiniAppSettings'
import MiniAppForm from '../components/MiniAppForm/MiniAppForm'
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import Documents from '../components/Documents/Documents'
import AppAnalytics from '../../Analytics/AppAnalytics/AppAnalytics'
import MiniAppBilling from '../MiniAppBilling'
import MiniAppPayout from '../MiniAppPayout'
import Badge, { BadgeStatus } from '../../components/Badge/Badge'
/*UTILS*/
import { goBack, normalizeMetrics, sortBy } from 'utils/utils'
/*SELECTORS*/
import { appCategoriesSelector } from 'store/showroom/selectors'
import {
  isProfileInitialLoad,
  profileRoleSelector,
} from 'store/profile/selectors'
import {
  miniAppPlansLoadedSelector,
  miniAppPlansSelector,
} from 'store/billing/selectors'
import {
  miniAppCountriesNamesByCodes as miniAppCountriesNamesByCodesSelector,
  miniAppCountriesNamesByContinent as miniAppCountriesNamesByContinentSelector,
} from '../../../../store/miniApps/selectors'
/*TYPES*/
import {
  ICategory,
  ICountriesByContinent,
  IFormData,
  IMiniApp,
  RootState,
} from 'types'
import { IMetrics } from 'types/Metrics'
import { IAppPlan, PaymentType } from 'types/Billing'
/*CONSTANTS*/
import { ROLE_NAMES } from 'utils/constants'
import SendEmailComplete from 'pages/Dashboard/Analytics/components/SendEmailComplete/SendEmailComplete'
import MiniAppLogs from '../components/MiniAppLogs/MiniAppLogs';
import MiniAppRequestSignature from '../components/MiniAppRequestSignature/MiniAppRequestSignature'

const { Title } = Typography
const { TabPane } = Tabs

const STATUS_COLORS: { [key: string]: BadgeStatus } = {
  Verified: 'success',
  'Pending verification': 'warning',
  'Not approved': 'default',
}

const APP_FORM_FIELDS = [
  'name',
  'short_description',
  'long_description',
  'publish',
  'summary_of_terms',
  'primary_color',
  'secondary_color',
  'tertiary_color',
  'status_bar_background',
  'webview_frame_size',
  'inherit_colors',
  'orientation',
  'action_button_theme',
  'action_button_position',
  'website',
  'hide_commercial_model',
  'single_sign_on_integrated',
  'get_auth_token_url',
  'payments_integrated',
  'webhook_url',
]

enum MiniAppDetailsTabs {
  Overview = 'overview',
  Documents = 'documents',
  Analytics = 'analytics',
  Billing = 'billing',
  Payouts = 'payouts',
  Settings = 'settings',
  Logs = 'logs',
  RequestSignature = 'request_signature',
}

interface IStateProps {
  categories: ICategory[]
  isAdmin: boolean
  isProfileLoaded: boolean
  miniAppPlans: IAppPlan[]
  miniAppPlansLoaded: boolean
  miniAppCountriesNamesByContinent: ICountriesByContinent[]
  miniAppCountriesNamesByCodes: (countriesCodes: string[]) => string[]
}

interface IDispatchProps {
  loadShowroomMiniApps: () => void
  getMiniapp: (appId: string) => Promise<AxiosResponse<IMiniApp>>
  updateMiniApp: (appId: string, formData: IFormData) => { miniApp: IMiniApp }
  deleteMiniAppScreenshots: (
    screenshotsIds: number[]
  ) => Promise<AxiosResponse<void>>
  getAppMetricsByRangeDate: (
    appId: number | string,
    [fromDate, toDate]: [moment.Moment, moment.Moment],
    isMiniApp: boolean
  ) => Promise<AxiosResponse<IMetrics[]>>
}

type Props = IStateProps & IDispatchProps

export const MiniAppDetails = ({
  isAdmin,
  categories,
  getMiniapp,
  miniAppPlans,
  updateMiniApp,
  isProfileLoaded,
  miniAppPlansLoaded,
  loadShowroomMiniApps,
  getAppMetricsByRangeDate,
  deleteMiniAppScreenshots,
  miniAppCountriesNamesByCodes,
  miniAppCountriesNamesByContinent,
}: Props) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(
    MiniAppDetailsTabs.Overview
  )
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false)
  const [metrics, setMetrics] = useState<IMetrics[]>([])
  const [app, setApp] = useState<IMiniApp | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const match = useRouteMatch()
  const history = useHistory()
  const { appId } = useParams<{ appId: string }>()
  const [ui, setUI] = useState({ sentEmail: false, csvModal: false })

  useEffect(() => {
    setActiveTabKey(getActiveTab())
    if (appId) {
      loadMiniApp(appId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId])

  const loadMiniApp = async (appId: string) => {
    try {
      const appResponse = await getMiniapp(appId)
      setApp(appResponse.data)
    } catch (error) {
      message.error('Something went wrong while loading miniapp')
    }
  }

  const onUpdateMiniApp = async () => {
    if (appId) {
      await loadMiniApp(appId)
      loadShowroomMiniApps()
    }
  }

  const handleClose = () => {
    goBack(history, '/miniapps')
  }

  const handleUpdate = async (data: IFormData) => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      if (data.upload && data.upload instanceof Blob) {
        formData.append('logo', data.upload, 'miniapp-logo.png')
      }

      APP_FORM_FIELDS.forEach((field) => {
        formData.append(field, isNull(data[field]) ? '' : data[field])
      })

      if (data.category) {
        formData.append('category.id', get(data, 'category.id', data.category))
      }
      if (data.required_fields.length === 0) {
        formData.append('required_fields', '')
      } else {
        data.required_fields.forEach((val: string) =>
          formData.append('required_fields', val)
        )
      }

      if (!!data.white_listed_urls.length) {
        data.white_listed_urls.forEach((url: string) =>
          formData.append('white_listed_urls', url)
        )
      } else {
        formData.append('white_listed_urls', '')
      }

      if (!!data.whitelisted_ips.length) {
        data.whitelisted_ips.forEach((url: string) =>
          formData.append('whitelisted_ips', url)
        )
      } else {
        formData.append('whitelisted_ips', '')
      }

      if (data.commercial_model?.length) {
        data.commercial_model.forEach((val: string) =>
          formData.append('commercial_model', val)
        )
      } else {
        formData.append('commercial_model', '')
      }

      if (data.countries?.length) {
        data.countries.forEach((val: string) =>
          formData.append('countries', val)
        )
      } else {
        formData.append('countries', '')
      }

      if (data.url) {
        formData.append(
          'url',
          data.url.startsWith('https://') ? data.url : `https://${data.url}`
        )
      } else {
        formData.append('url', '')
      }

      if (data.get_token_url) {
        formData.append(
          'get_token_url',
          data.get_token_url.startsWith('https://')
            ? data.get_token_url
            : `https://${data.get_token_url}`
        )
      } else {
        formData.append('get_token_url', '')
      }

      if (data.maintenance_page_url) {
        formData.append(
          'maintenance_page_url',
          data.maintenance_page_url.startsWith('https://')
            ? data.maintenance_page_url
            : `https://${data.maintenance_page_url}`
        )
      } else {
        formData.append('maintenance_page_url', '')
      }

      const updateAction = await updateMiniApp(appId!, formData)
      if (updateAction.miniApp) {
        setApp(updateAction.miniApp)
        loadShowroomMiniApps()
      }
      message.success('Successfully saved')
      setIsLoading(false)
    } catch (error) {
      console.log('Error', error)
      if (get(error, 'response.data.length') === 1) {
        message.error(get(error, 'response.data[0]'))
      } else {
        message.error('Something went wrong while updating miniapp')
      }
      setIsLoading(false)
    }
  }

  const handleUpdateWithDeleteScreenshots = async (data: IFormData) => {
    if (!app) {
      return
    }
    try {
      setIsLoading(true)
      const screenshotsIds = app.screens.map((screen) => screen.id)
      await deleteMiniAppScreenshots(screenshotsIds)
      await handleUpdate(data)
    } catch (e) {
      message.error('Something went wrong while deleting screenshots')
    }
  }

  const onChangeAnalyticsDate = async (values: RangeValue<moment.Moment>) => {
    if (values?.[0] && values?.[1]) {
      try {
        setIsLoadingMetrics(true)
        const range: [moment.Moment, moment.Moment] = [values[0], values[1]]

        const metricsResponse = await getAppMetricsByRangeDate(
          app!.id,
          range,
          true
        )
        setMetrics(normalizeMetrics(metricsResponse.data))
        setIsLoadingMetrics(false)
      } catch (e) {
        setIsLoadingMetrics(false)
        message.error('Something went wrong while loading analytics')
      }
    }
  }

  let badge = null
  if (app) {
    badge = <Badge status={STATUS_COLORS[app.status]} label={app.status} />
  }

  const handleTabChange = (activeTab: string) => {
    setActiveTabKey(activeTab)
    history.replace(`${match.url}/${activeTab}`)
  }

  const getActiveTab = () => {
    // TODO: Handle it with routing architecture
    const p = history.location.pathname
    return p.split('/')[3]
  }

  let hasBillingPlans = false
  let hasPayoutPlans = false
  miniAppPlans.forEach((plan) => {
    if (plan.miniapp.id === +appId!) {
      hasBillingPlans =
        hasBillingPlans || plan.billing_type === PaymentType.Billing
      hasPayoutPlans =
        hasPayoutPlans || plan.billing_type === PaymentType.Payout
    }
  })
  const renderBilling = isAdmin && hasBillingPlans
  const renderPayouts = isAdmin && hasPayoutPlans
  const renderRequestSignature = app?.status === 'Verified'
  if (ui.sentEmail)
    return (
      <SendEmailComplete
        text='The exported user consent statuses will be sent to your inbox within a few minutes.'
        handleClose={() => {
          setUI({ sentEmail: false, csvModal: false })
        }}
      />
    )

  return (
    <SinglePageLayout onGoBack={handleClose}>
      {app && (
        <Title>
          {app.name} {badge}
        </Title>
      )}
      <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        <TabPane tab="Overview" key={MiniAppDetailsTabs.Overview} />
        <TabPane tab="Documents" key={MiniAppDetailsTabs.Documents} />
        <TabPane tab="Analytics" key={MiniAppDetailsTabs.Analytics} />
        {renderBilling && (
          <TabPane tab="Billing" key={MiniAppDetailsTabs.Billing} />
        )}
        {renderPayouts && (
          <TabPane tab="Payouts" key={MiniAppDetailsTabs.Payouts} />
        )}
        <TabPane tab="Settings" key={MiniAppDetailsTabs.Settings} />
        <TabPane tab="Logs" key={MiniAppDetailsTabs.Logs} />
        {renderRequestSignature && (
          <TabPane tab="Request Signature" key={MiniAppDetailsTabs.RequestSignature} />
        )}
      </Tabs>
      {app && isProfileLoaded && miniAppPlansLoaded ? (
        <RouterSwitch>
          <Route path={`${match.url}/${MiniAppDetailsTabs.Overview}`}>
            <MiniAppForm
              app={app}
              isSaving={isLoading}
              categories={categories}
              onSubmit={handleUpdate}
              updateMiniApp={onUpdateMiniApp}
              countries={miniAppCountriesNamesByContinent}
              miniAppCountriesNamesByCodes={miniAppCountriesNamesByCodes}
              onRedirectToSettings={() =>
                handleTabChange(MiniAppDetailsTabs.Settings)
              }
            />
          </Route>
          <Route path={`${match.url}/${MiniAppDetailsTabs.Documents}`}>
            <Documents appId={appId!} />
          </Route>
          <Route path={`${match.url}/${MiniAppDetailsTabs.Analytics}`}>
            <AppAnalytics
              metrics={metrics}
              isLoading={isLoadingMetrics}
              onChangeDate={onChangeAnalyticsDate}
              miniApp={app}
              ui={ui}
              setUI={setUI}
            />
          </Route>
          <Route path={`${match.url}/${MiniAppDetailsTabs.Settings}`}>
            <MiniAppSettings
              app={app}
              isSaving={isLoading}
              onSubmit={handleUpdate}
              onSubmitWithDeleteScreenshots={handleUpdateWithDeleteScreenshots}
            />
          </Route>
          <Route path={`${match.url}/${MiniAppDetailsTabs.Logs}`}>
            <MiniAppLogs appId={appId!} />
          </Route>
          {renderRequestSignature && (
            <Route path={`${match.url}/${MiniAppDetailsTabs.RequestSignature}`}>
              <MiniAppRequestSignature appId={appId!} settings={app?.request_signature_settings} loadMiniApp={loadMiniApp} />
            </Route>
          )}
          {renderBilling && (
            <Route path={`${match.url}/${MiniAppDetailsTabs.Billing}`}>
              <MiniAppBilling appId={appId!} />
            </Route>
          )}
          {renderPayouts && (
            <Route path={`${match.url}/${MiniAppDetailsTabs.Payouts}`}>
              <MiniAppPayout appId={appId!} />
            </Route>
          )}
          <Route path="*">
            <Redirect
              to={{ pathname: `${match.url}/${MiniAppDetailsTabs.Overview}` }}
            />
          </Route>
        </RouterSwitch>
      ) : (
        <AppSkeleton />
      )}
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState): IStateProps => {
  return {
    isAdmin: profileRoleSelector(state) === ROLE_NAMES.ADMIN,
    isProfileLoaded: !isProfileInitialLoad(state),
    categories: appCategoriesSelector(state).sort(sortBy('name')),
    miniAppPlans: miniAppPlansSelector(state),
    miniAppPlansLoaded: miniAppPlansLoadedSelector(state),
    miniAppCountriesNamesByContinent:
      miniAppCountriesNamesByContinentSelector(state),
    miniAppCountriesNamesByCodes: (countriesCodes: string[]) =>
      miniAppCountriesNamesByCodesSelector(countriesCodes, state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    ...bindActionCreators<any, any>(
      {
        getMiniapp: getMiniappAction,
        updateMiniApp: updateMiniAppAction,
        loadShowroomMiniApps: loadShowroomMiniAppsAction,
        deleteMiniAppScreenshots: deleteMiniAppScreenshotsAction,
        getAppMetricsByRangeDate: getAppMetricsByRangeDateAction,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniAppDetails)
