/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import { Button, message } from 'antd'
/*ACTIONS*/
import {
  addSuperApp as addSuperAppAction,
  loadSuperApps as loadSuperAppsAction
} from 'store/superApps/actions'
/*COMPONENTS*/
import SuperAppBasicInfo, { SuperAppBasicInfoFormData } from '../components/SuperAppBasicInfo/SuperAppBasicInfo'
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import ResultPane from '../../components/Result/Result'
/*UTILS*/
import { goBack } from 'utils/utils'
import { useMutation } from '@tanstack/react-query'


interface Props {
  loadSuperApps: Function
}

export const CreateSuperApp = ({  loadSuperApps }: Props) => {
  const [hostappId, setHostappId] = useState<number | null>(null)
  const history = useHistory()
  const { mutateAsync: addSuperApp, isLoading } = useMutation({
    mutationFn: addSuperAppAction,
  })

  const handleClose = () => {
    goBack(history, '/host-apps')
  }

  const goToSuperAppDetails = () => {
    history.replace(`/host-apps/${hostappId}/overview`)
  }

  const handleSubmit = async (superAppData: SuperAppBasicInfoFormData) => {
    try {
      const formData = new FormData()
      formData.append('name', superAppData.name)

      const response = await addSuperApp(formData)
      setHostappId(response.data.id)
      await loadSuperApps()
    } catch (error) {
      message.error('Something went wrong while creating host app. Please try again.')
    }
  }

  return (
    <SinglePageLayout
      contentPadding='large'
      onClose={handleClose}
      isFullHeight
    >
      {!hostappId ? (
        <SuperAppBasicInfo
          isLoading={isLoading}
          onSubmit={handleSubmit}
        />
      ) : (
        <ResultPane
          title='You have added your host app'
          text='Continue to dashboard to get secret key and client id'
        >
          <Button
            type='primary'
            size='large'
            block
            style={{ width: '340px' }}
            onClick={goToSuperAppDetails}
          >
            Continue
          </Button>
        </ResultPane>
      )}
    </SinglePageLayout>
  )
}


const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      loadSuperApps: loadSuperAppsAction
    }, dispatch),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(CreateSuperApp)
