import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_ACTIVE_MINIAPPS, API_USER_HOST_APPS } from "../utils/constants";
import { IRequestSignature } from "types/RequestSignature";

function updateHostAppRequestSignature(appId: string, data: IRequestSignature) {
  return axios.post(
    API_USER_HOST_APPS + appId + "/request_signature_settings/",
    data
  );
}

export const useUpdateHostAppRequestSignature = (id: string) => {
  return useMutation({
    mutationFn: (data: IRequestSignature) =>
      updateHostAppRequestSignature(id, data),
  });
};

function updateMiniAppRequestSignature(appId: string, data: IRequestSignature) {
  return axios.post(
    API_ACTIVE_MINIAPPS + appId + "/request_signature_settings/",
    data
  );
}

export const useUpdateMiniAppRequestSignature = (id: string) => {
  return useMutation({
    mutationFn: (data: IRequestSignature) =>
      updateMiniAppRequestSignature(id, data),
  });
};
