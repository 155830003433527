import React, { FC } from 'react';
import { useGetHostAppLogs } from '../../../../../queries/logs';
import LogsTable from '../../../../common/LogsTable';

interface Props {
  appId: string
}

const SuperAppLogs: FC<Props> = ({appId}) => {
  const {data, isLoading} = useGetHostAppLogs(appId);

  return <LogsTable data={data || []} loading={isLoading} />
}

export default SuperAppLogs;
