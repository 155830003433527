import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ILogItem } from '../types';
import { API_USER_APPS, API_USER_HOST_APPS } from '../utils/constants';

interface ApiError {
  message: string;
}

export const useGetHostAppLogs = (id: string) => {
  return useQuery<ILogItem[], ApiError>(['useGetHostAppLogs', id], async () => {
    const response = await axios.get<ILogItem[]>(
      `${API_USER_HOST_APPS}${id}/request_logs/`
    );
    return response.data;
  });
};

export const useGetMiniAppLogs = (id: string) => {
  return useQuery<ILogItem[], ApiError>(['useGetMiniAppLogs', id], async () => {
    const response = await axios.get<ILogItem[]>(
      `${API_USER_APPS}${id}/request_logs/`
    );
    return response.data;
  });
};
